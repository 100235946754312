<template>
	<div class="text-center">
		<v-checkbox
			class="no-padding pt-0 pb-2"
			hide-details
			v-model="item[column.value]"
			@change="update"
		>
		</v-checkbox>
			<chase-or-check-consent
				:value="item.participant_id"
			></chase-or-check-consent>
	</div>
</template>

<style lang="less">
.no-padding .v-input__control {
	flex-grow: 0;
	display: inline;
	width: auto;
}
</style>

<script>
import ChaseOrCheckConsent from "@c/consent/ChaseOrCheckConsent";
export default {
	name: "ConsentField",
	props: ["item", "column"],
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		ChaseOrCheckConsent,
	},
	methods: {
		update(v) {
			this.$store.dispatch("appointmentParticipants/patch", {
				id: this.item.participant_id,
				[this.column.value]: v ? true : false,
			});
		},
	},
};
</script>
